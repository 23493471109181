<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-form :inline="true" label-width="80px">
        <el-form-item label="发布人:">
          <el-input
            style="width: 140px"
            v-model="queryInfo.condition.memberName"
            clearable
            placeholder="发布人"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-input
            style="width: 140px"
            v-model="queryInfo.condition.circleName"
            clearable
            placeholder="圈子名称"
          ></el-input>
        </el-form-item>
        <el-button type="primary" :loading="tableLoading" @click="getList"
          >搜索</el-button
        >
      </el-form>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        height="calc(100vh - 240px)"
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          label="创建用户名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <el-table-column
          label="创建用户头像"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              :preview-src-list="[scope.row.memberLogo]"
              style="width: 60px; height: 60px"
              :src="scope.row.memberLogo"
            ></el-image>
          </template>
        </el-table-column>

        <!-- <el-table-column label="圈子logo" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.circleLogo }}</template>
          </el-table-column> -->
        <el-table-column label="圈子名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.circleName }}</template>
        </el-table-column>
        <el-table-column
          label="圈子介绍图"
          align="center"
        >

          <template slot-scope="scope">

             <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.circleUrl"
              :preview-src-list="[scope.row.circleUrl]"
            >
            </el-image>

            <!-- {{ scope.row.circleUrl }} -->
            </template>
        </el-table-column>
        <el-table-column
          label="加入人员数量"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.joinNum }}</template>
        </el-table-column>
        <el-table-column label="审核状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success">已通过</el-tag>
            <el-tag type="warning" v-if="scope.row.status == 2">未通过</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDt }}</template>
        </el-table-column>
        <el-table-column label="标签" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.label }}</template>
        </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small"
              >审核</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="blocks">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="审核" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="审核结果" prop="siteName">
              <el-select v-model="form.status">
                <el-option value="1" label="通过"></el-option>
                <el-option value="2" label="不通过"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          circleName: "",
          memberName: "",
        },
      },
      form: {
        status: "",
      },
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    async getList() {
      this.tableLoading = true;
      let res = await this.$http.post("/circle/list", this.queryInfo);
      this.tableLoading = false;
      if (res.data.code == 200) {
        this.total = res.data.data.count;
        this.queryInfo.currPage = res.data.data.currPage;
        this.tableData = res.data.data.data;
      }
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http
        .post("/circle/save", that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {});
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/circle/delete", { id: id }).then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped></style>
